.panel--work {
    background: linear-gradient(to bottom, #5aa700 5%, #0a4d59 60%);
    &:--us{

    background: linear-gradient(to bottom, #5aa700 5%, #0c182c 60%);
    }
}

.work-panel__header-wrapper {
    @include grid-row;
    background: url(/_assets/img/work-panel__bg.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    &--no-bg {
        background:none;

    }
}

.work-panel__header {
    padding: 1rem;
    @include breakpoint(medium) {
        padding: 3rem;
        padding-bottom: 2rem;
    }
    p {
        line-height: 1.5rem;
        margin-bottom: 1rem;
    }
    text-align: center;
    &--us {
        padding-bottom: 0;
    }
}

.work-panel__logo {
    text-align: center;
    svg {
        width: 3rem;
    }
    path {
        fill: $brand-white;
    }
}

.work-panel__projects {
    margin-top: 2rem;
    @include grid-column(12);
    text-align: center;
    @include grid-layout(1, '.project,.grid-sizer');
    @include breakpoint(medium) {
        @include grid-layout(2, '.project');
    }
    &--us {
        margin-top: 0;
    }
}



.project {
    padding: 2rem;
    @include breakpoint(large) {
        padding-left: 1rem;
    }
    a {
        transition: color .3s ease-in-out;
        font-family: $header-font-family;
        &:hover {
            color: $brand-white;
        }
    }
}

.project__image {
    transition: transform .5s ease-in-out;
        transform: scale(1.01);
    &:hover {
        transform: scale(1.15);
    }
}

.work-panel__projects {
.project__title {
    margin-top: 1.2rem;
    font-size: 1.5rem;
    line-height: 1.6rem;
    margin-bottom: 0;
    transition: color .3s ease-in-out;
    color: $brand-white;
}
}

//Active nav item highlight
.panel--work.is-open~.panel--menu {
    .nav__item1 {
        background-position: 100% 50%;
    }
}