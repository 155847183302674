footer {
    @include breakpoint(medium) {
        position: absolute;
        bottom: 3rem;
        width: 100%;
    }
}

.internal-page {
    footer {
        bottom: 0;
        @include breakpoint(small only) {
            bottom: -3rem;
            position: absolute;
            width: 100%;
        }
    }
}

.social-icons {
    @include grid-column(12);
    padding-top: 3rem;
    text-align: center;
    padding-bottom: 1rem;
    z-index: 5;
    font-size: 1.5rem;
    @include breakpoint(medium) {
        padding: 0px;
    }
    a {
        padding: .5rem;
    }
}

.fine-print {
    @include grid-column(12);
    margin-bottom: 7rem;
    font-size: .8rem;
    text-align: center;
    span {
        display: block;
    }
    @include breakpoint(medium) {
        margin-bottom: 0;
        span {
            display: inline-block;
        }
        margin-top: 1rem;
        span:not(:first-of-type) {
            margin-left: 2rem;
        }
        z-index: 5;
    }
    a {
        color: $brand-white;
    }
}