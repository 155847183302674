//to Prevent ios input jumping

.nav__item1 {
    animation-delay: .3s;
}

.nav__item2 {
    animation-delay: .4s;
}

.nav__item3 {
    animation-delay: .5s;
}

.nav__item4 {
    animation-delay: .6s;
}

.nav__item {
    animation-fill-mode: both;
    margin-bottom: 2rem;
    padding-left: 2rem;
    width: 100%;
    background: linear-gradient(135deg, rgba(30, 87, 153, 0) 10%, rgba(25, 86, 93, 1) 69%, rgba(23, 85, 67, 1) 99%);
    background-size: 400% 200%;
    background-position: -30% 50%;
    background-repeat: no-repeat;
    transition: background-position 1s ease-in-out;
    padding-top: 12px;
    &:hover {
        background-position: 100% 50%;
    }
}

.nav {
    @include breakpoint(medium) {
        margin-bottom: 5rem;
    }
}

.button-group--menu {
    animation-delay: 1s;
    position: absolute;
    right: 2rem;
}

.button-group__button--menu {
    background-color: #175543;
}

.is-open {
    .nav__item {
        @include mui-animation(slide(in, left, 20%), fade);
        animation-duration: 1s;
    }
    .button-group {
        @include mui-animation(slide(in, up, 20%), fade);
        animation-duration: 1s;
    }
}

//stops page from jumping when scrollbar goes away
.is-off-canvas-open {
    @include breakpoint(xlarge) {
        // padding-right: 15px;
    }
}

.off-canvas {
    overflow: hidden;
    background-image: -webkit-linear-gradient(top, #5aa700 0%, #357e28 50%, #159477 100%);
    background-image: -o-linear-gradient(top, #5aa700 0%, #357e28 50%, #159477 100%);
    background-image: linear-gradient(to bottom, #5aa700 0%, #357e28 50%, #159477 100%);
    background-repeat: no-repeat;
    @include breakpoint(medium) {
        width: 380px;
    }
}

.js-off-canvas-overlay {
    background: rgba(45, 62, 74, 0.8);
    z-index: 5;
}

.menu-content {
    position: absolute;
    bottom: 5rem;
    width: 100%;
}

.nav__icon {
    width: 2.4rem;
    display: inline-block;
    position: relative;
    top: 0.3rem;
    margin-right: 1rem;
}

.nav__text {
    display: inline-block;
    color: $brand-white;
    font-size: 2.5rem;
}

.panel--sub.is-open {
    @include breakpoint(large) {
        transform: translate(-380px, 0);
    }
}

.panel--sub {
    z-index: 11 !important;
    width: 100%;
    @include breakpoint(large) {
        z-index: 9 !important;
    }
    @include breakpoint(large) {
        width: 650px;
        transform: translateX(650px);
    }
}

.off-canvas {}

.panel__content {
    //Hiding scrollbar, but still scrollable.
    @include breakpoint(large) {
        margin-right: -17px;
    }
    padding-bottom: 3rem;
    overflow: scroll;
    overflow-x:hidden;
    /* has to be scroll, not auto */
    -webkit-overflow-scrolling: touch;
    height: 101%;
}

.menu-open {
    overflow: hidden;
}

.close-button,
.close-button:focus {
    @include breakpoint(medium down) {
        display: none;
    }
    background-color: $brand-white;
    border-radius: 100px;
    color: $brand-green;
    padding: .4rem .5rem;
    font-size: 1.2rem;
    transition: all .3s ease-in-out;
    outline: none;
    z-index: 9999;
}

.close-button:hover {
    color: $body-background;
    transform: translateX(5px);
}


.panel--us.is-open ~ .panel--menu {
    .nav__item2 {
        background-position: 100% 50%;
    }
}
