.culture-graphic {
    overflow: visible !important;
    .c-graphic-the {
         animation: .7s c-the-in cubic-bezier(0.25, 0.46, 0.45, 0.94);
        animation-fill-mode: both;
        animation-delay: 1.5s;

    }
     .c-graphic-little {
         animation: 1s c-little-in cubic-bezier(0.25, 0.46, 0.45, 0.94);
        animation-fill-mode: both;
        animation-delay: 1.7s;

    }
    .c-graphic-things{
         animation: 1s c-things-in cubic-bezier(0.25, 0.46, 0.45, 0.94);
        animation-fill-mode: both;
        animation-delay: 1.9s;
    }
    .c-graphic-always{
         animation: 1s c-always-in cubic-bezier(0.25, 0.46, 0.45, 0.94);
        animation-fill-mode: both;
        animation-delay: 2.1s;
    }
    .c-graphic-matter{
         animation: 1s c-matter-in cubic-bezier(0.25, 0.46, 0.45, 0.94);
        animation-fill-mode: both;
        animation-delay: 2.4s;
    }
    .c-graphic-acorn{
         animation: .5s c-acorn-in cubic-bezier(0.25, 0.46, 0.45, 0.94);
        animation-fill-mode: both;
        animation-delay: 2.7s;
    }
}

@keyframes c-the-in {
   from {
    opacity: 0;
    transform: translateX(-30%);

   }

   to {
    transform: translateX(0%);
    opacity: 1;

   }
}

@keyframes c-little-in {
   from {
    opacity: 0;
    transform: translateY(20%);

   }

   to {
    transform: translateY(0%);
    opacity: 1;

   }
}
@keyframes c-things-in {
   from {
    opacity: 0;
    transform: translateX(-50%);

   }

   to {
    transform: translateX(0%);
    opacity: 1;

   }
}

@keyframes c-always-in {
   from {
    opacity: 0;
    transform: translateX(-8%) translateY(30%);

   }

   to {
    transform: translateX(0%) translateY(0%);
    opacity: 1;

   }
}

@keyframes c-matter-in {
   from {
    opacity: 0;
    transform: translateY(30%);

   }

   to {
    transform: translateY(0%);
    opacity: 1;

   }
}

@keyframes c-acorn-in {
   from {
    opacity: 0;
    transform: translateY(-50%);

   }

   to {
    transform: translateY(0%);
    opacity: 1;

   }
}