.news {
    @include grid-column(12);
}



.slick__button-cont {
    display: none;
    @include breakpoint(medium){
        display: block;
    }

    @include grid-column(1);
    padding-top: 1rem;
    &--next {
        @include grid-column-position(1);
        @include breakpoint(medium) {
            padding-top: 0rem;
            @include grid-column-position(10);
        }
    }
}

.slick__button {
    cursor: pointer;
    position: relative;
    top: 40%;
    transform: translateY(-40%);
    svg {
        transform: rotate(90deg);
        width: 2rem;
    }
    path {
        fill: $brand-green;
        transition: fill .3s ease-in-out;
    }
    &:hover {
        path {
            fill: $brand-white;
        }
    }
    &--next {
        text-align: right;
        margin-top: 0.6rem;
        svg {
            transform: rotate(-90deg);
        }
    }
}

.stories {
    @include grid-column(12);
    @include breakpoint(medium) {
        @include grid-column(10);
        @include grid-column-position(-1);
    }
}

.story {
    overflow: hidden;
    &:focus {
outline: none;
    }
}

.story__title-wrapper {
    background-color: $brand-green;
    width: 100%;
    &:hover {
        background-color: $brand-white;
    }
    transition: all .3s ease-in-out;
    @include breakpoint(large) {
        width: 90%;
    }
    display: inline-block;
}

.story__title {
    color: $brand-white;
    transition: all .3s ease-in-out;
    @include grid-column(12);
    @include breakpoint(small only) {
        padding: 1rem 1.5rem;
    }
    @include breakpoint(medium) {
        margin: 1rem 1.5rem;
    }
    @include breakpoint(large) {
        @include grid-column(7);
    }
    font-size: 1.5rem;
    line-height: 1.6rem;
    margin: 0;
}

a .story__title {
    &:hover {
        color: $brand-green;
    }
}

.story__body {
    @include grid-column(12);
    @include breakpoint(large) {
        @include grid-column(7);
        @include grid-column-position(-5);
    }
    margin-top: 1.5rem;
    a {
        margin-left: 1.5rem;
    }
}

.story__copy {
    padding-left: 1rem;
    padding-right: 1rem;
    margin-bottom: 1rem;
    @include breakpoint(medium) {
        margin-bottom: 3rem;
        padding-left: 1.5rem;
    }
}

.story__image {
    @include grid-column(12);
    text-align: center;
    @include breakpoint(large) {
        @include grid-column(5);
        @include grid-column-position(7);
        top: -4rem;
    }
    img {
        display: inline-block;
        transition: border .2s ease-in-out;
        &:hover {
            border: 6px solid $brand-white;
        }
        @include breakpoint(large) {
            border: 6px solid #0c182c;
        }
    }
}
