.featured-works {
    text-align: center;
}

.featured-works__header {
    @include grid-column(12);
    @include breakpoint(medium) {
        margin-bottom: 6rem;
    }
    @include breakpoint(large) {
        @include grid-column(6);
    }
    @include grid-column-position(center);
    @include breakpoint(small only) {
        p {
            text-align: left;
            padding: 1rem;
            margin-bottom: 1rem;
        }
    }
}

.featured-works__logo {
    width: 4rem;
    display: inline-block;
    path {
        fill: #55a700;
    }
}

.work-feature {
    @include grid-column(12);
    margin-bottom: 5rem;
    &--portfolio {
        margin-bottom: .5rem;
    }
}

.work__text {
    @include grid-column(12);
    margin-top: 1rem;
    @include breakpoint(large) {
        @include grid-column(6);
        &--reverse {
            @include grid-column-position(-6);
        }
        margin-top: 4rem;
        padding-top: 4rem;
    }
    background-color: #0c182c;
    background-image: url(/_assets/img/wood_transparent.png);
    &--portfolio {
        background-color: transparent;
        background-image: none;
        margin-top: 0rem;
        padding-top: 1rem;
        @include breakpoint(medium) {
            padding-top: 3rem;
        }
        @include breakpoint(xlarge) {
            margin-top: 4rem;
            padding-top: 4rem;
        }
    }
}

.work__small-text {
    @include breakpoint(medium) {
        float: right;
        padding-right: 0;
        text-align: left;
        margin-left: 8rem;
        &--reverse {
            float: none;
            margin-left: 1rem;
            padding-right: 8rem;
        }
    }
    @include breakpoint(small only) {
        .button {
            //display: none;
        }
    }
}

.work__copy {
    padding-bottom: 1rem;
    border-bottom: 2px solid $brand-green;
    font-size: 1rem;
    @include breakpoint(large) {
        font-size: 1.2rem;
    }
    line-height: 1.5rem;
    margin-bottom: .5rem;
    text-align: left;
}

.work__services--text-only {
    color: $brand-green;
    font-size: .8rem;
}

.work__services {
    padding-top: 1rem;
    padding-bottom: 1rem;
    @include grid-layout(2, '.service');
    overflow: hidden;
}

a .work__title {
    &:hover {
        background-color: $brand-white;
        color: $brand-green;
    }
}

.work__title {
    background-color: $brand-green;
    color: $brand-white;
    transition: all .3s ease-in-out;
    font-size: 1.8rem;
    line-height: 2rem;
    text-align: left;
    @include breakpoint(medium) {
        font-size: 2.5rem;
        line-height: 2.8rem;
    }
    padding: 1rem 2rem;
    padding-left: 1.3rem;
    position: relative;
    z-index: 3;
}

.u-wrap {
    border-radius: 25% 25% 100% 100%;
    overflow: hidden;
    position: relative;
    z-index: 1;
    transform: translate3d(0,0,0);
    img {
        max-width: 101%;
    }
}

.work__image {
    @include grid-column(12);
    @include breakpoint(small only) {
        margin-top: 1.5rem;
    }
    @include breakpoint(medium) {
        @include grid-column(8);
        @include grid-column-position(1);
    }
    @include breakpoint(large) {
        position: relative;
        z-index: 2;
        @include grid-column(6);
        &--reverse {
            @include grid-column-position(5);
        }
        .u-wrap {
            width: 100%;
        }
    }
    img {
        transition: transform .5s ease-in-out;
    }
    &:hover {
        img {
            transform: scale(1.05);
        }
    }
    &--portfolio {
        pointer-events: none;
        @include breakpoint(small only) {
            margin-top: -2.5rem;
            margin-bottom: 1rem;

        }
        @include breakpoint(medium) {}
        @include breakpoint(large) {
            img {
                margin-bottom: 0rem;
            }
        }
    }
}