.work-body {
    @include breakpoint(small only) {
        background: none;
        background-color: #0b4052;
    }
    background: linear-gradient(45deg, #0a4d59 20%, #0c182c 100%);
    footer {
        bottom: 3rem;
        &::after {
            content: "";
            background: url(/_assets/img/bottom-trees.png) repeat-x bottom;
            height: 200px;
            width: 100%;
            top: -66px;
            display: inline-block;
            position: absolute;
            z-index: -1;
            image-rendering: pixelated;
            @include breakpoint(small only){
                display: none;
            }
        }
    }
}

.work-page__projects {
    text-align: center;
    @include breakpoint(small only) {
        margin-top: 1rem;
        margin-bottom: 20rem;
    }
}

.work__project-text {
    position: relative;
    top: -2rem;
    background-image: url(/_assets/img/wood_transparent.png);

    padding: 3rem 1rem;
    @include breakpoint(medium) {
        padding: 3rem 2rem 2rem 2rem;
    }
}

.work__project-title {
    font-size: 2rem;
    color: $brand-white;
}

.work__project-categories {
    color: $brand-green;
    font-size: 1rem;
}

.work-page-filters {
    text-align: center;
    width: 100%;
    padding-top: 1rem;
}

.project-categories {
    position: absolute;
    width: 100%;
    top: -36rem;
    padding-top: 3rem;
    left: 0;
    z-index: 4;
    background-color: #0c182c;
    background-image: url(/_assets/img/wood_transparent.png);
    transition: top .5s ease-in-out;
    @include breakpoint(small only) {
        top: -101vh;
        height: 101vh;
        padding-top: 4rem;
        h4 {
            font-size: 2rem;
        }
    }
    ul {
        list-style: none;
        display: inline-block;
        margin: 0;
    }
    box-shadow: #0c182c 0px 0px 70px;
    li {
        text-transform: uppercase;
    }
    li:not(:last-of-type) {
        margin-bottom: 1.3rem;
    }
    li:last-of-type {
        margin-top: 2rem;
    }
    h4 {
        font-size: 1rem;
    }
}

.project-categories--open {
    top: 0rem;
}

.work-page__image {
    position: relative;
    z-index: 2;
}

.work__title--center {
    text-align: center;
}

.work__categories {
    color: $brand-green;
    p {
        font-size: .9rem;
        line-height: 1.2rem;
        margin-bottom: 0;
    }
}

.work__client {
    font-family: $header-font-family;
    p {
        font-size: 1.2rem;
        line-height: 1.2rem;
        margin-bottom: .2rem;
    }
}


.works {

    .project,
    .grid-sizer {
        @include breakpoint(small only) {
            padding-bottom: .1rem;
            padding-top: .1rem;
        }
        width: 100%;
        @include breakpoint(medium) {
            width: 50%;
        }
        @include breakpoint(large) {
            width: 33.333333%;
        }
    }
}

.active {
    color: white !important;
}

.cat-overlay {
    @include breakpoint(small only) {
        display: none;
    }
    background-color: $black;
    height: 200vh;
    width: 100vw;
    position: absolute;
    top:0;
    left: 0;
    z-index: 3;
    opacity: 0;
    pointer-events:none;
    transition: opacity .2s ease-in-out;
    &.open {
        opacity: .4;
        pointer-events: all;
    }
}

.cats-open {
    overflow: hidden;
}