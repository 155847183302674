.floating-nav {
    position: absolute;
    width: 100%;
    z-index: 9999;
    padding: .6rem 1rem .5rem 1rem;
    @include breakpoint(large) {
        padding: 1rem 3rem;
        padding-top: 1rem;
    }
    pointer-events: none;
}

.floating-nav--top {
    @include breakpoint(small only) {
        display: none;
    }
}

.floating-nav--bottom {
    position: fixed;
    bottom: 0px;
    padding-bottom: 15px;
    transform: translateY(100%);
    transition: transform .5s ease-in-out;
        @include breakpoint(small only){
            transform: none;
            animation: none;
        }
}


.floating-nav--bottom-visible {
    transform: translateY(0%);

}

.floating-nav__logo {
    float: left;
    padding-top: .5rem;
    pointer-events: all;
    path {
        transition: fill 1s ease;
        fill: $brand-green;
    }
    width: 8rem;
    @include breakpoint(medium) {
        width: 10rem;
    }
}

.floating-nav__logo--top {
    path {
        fill: $brand-white;
    }
}


.portfolio {
    .floating-nav {
        @include breakpoint(small only) {
            background: linear-gradient(rgba(30, 87, 153, 0) 50%, white 50%);
            background-size: 400% 400%;
            background-position-y: 120px;

        }
        transition: background-position-y 1s ease;
    }

    .floating-nav__menu-group {
        @include breakpoint(small only) {
            padding-top: 0.2rem;
        }
    }


    .floating-nav__logo {
    padding-top: .8rem;
    }
    .floating-nav__logo--top {
        path {
            fill: $brand-green;
        }
    }
    .floating-nav__email {
        color: $brand-green;
    }
    .button-group__text {
        color: $brand-green;
    }
    &.menu-open {
        .button-group__text {
            color: $brand-white;
        }
    }

    .button-group__button--top-nav {
        background-color: $brand-green;
        span {
            background-color: $brand-white;
        }
        &:hover {
            background-color: #175543;
            span {
                background-color: $brand-white;
            }
        }
    }
}

.work-body {
    .floating-nav__email:hover {
        color: $brand-green;
    }
}

 .button-group__button{
        @include breakpoint(small only) {
            padding: 1.1rem;
            span {
                    width: 15px;
    height: 4px;
            }
        }
    }
.menu-open {
    .floating-nav {
        background-position-y: 220px;
    }
    .floating-nav__logo {
        path {
            fill: $brand-white;
        }
    }
    .button-group__button--top-nav {
        background-color: #175543;
        span {
            background-color: $brand-white;
        }
    }
}


.floating-nav__menu-group {
    float: right;
    pointer-events: all;
}


.floating-nav__email {
    @include breakpoint(small only) {
        display: none;
    }
    color: $brand-white;
    left: -4rem;
    position: relative;
    top: .3rem;
    font-size: .8rem;
    transition: color .3s ease-in-out;
    &:hover {
        color: $body-background;
    }
}