.services-graphic {
    overflow: visible !important;
    .s-graphic-bg {
        animation: 1s s-bg-in cubic-bezier(0.25, 0.46, 0.45, 0.94);
        animation-fill-mode: both;
        animation-delay: 1s;
    }
    .s-graphic-swatch {
        animation: 1s s-swatch-in cubic-bezier(0.25, 0.46, 0.45, 0.94);
        animation-fill-mode: both;
        animation-delay: 1.5s;
        transform-origin: center;
    }
    .s-graphic-brand {
        animation: .5s s-brand-in cubic-bezier(0.25, 0.46, 0.45, 0.94);
        animation-fill-mode: both;
        animation-delay: 2.2s;
    }
    .s-graphic-tech {
        animation: .5s s-tech-in cubic-bezier(0.25, 0.46, 0.45, 0.94);
        animation-fill-mode: both;
        animation-delay: 2.2s;
    }
    .s-graphic-business {
        animation: .5s s-business-in cubic-bezier(0.25, 0.46, 0.45, 0.94);
        animation-fill-mode: both;
        animation-delay: 2.2s;
    }
    .s-graphic-us {
        animation: 1s s-us-in cubic-bezier(0.25, 0.46, 0.45, 0.94);
        animation-fill-mode: both;
        animation-delay: 2.5s;
    }
    .s-graphic-lines {
        path {
            animation: .5s s-lines-in cubic-bezier(0.25, 0.46, 0.45, 0.94);
            animation-fill-mode: both;
            animation-delay: 2.8s;
            stroke-dasharray: 1000px;
            stroke-dashoffset: 1730;

        }
    }
}

@keyframes s-bg-in {
    from {
        opacity: 0;
        transform: translateY(20%);
    }
    to {
        opacity: 1;
        transform: translateY(0%);
    }
}


@keyframes s-swatch-in {
    from {
        opacity: 0;
        transform: rotate(180deg) scale3d(.8, .8, 1);
    }
    to {
        opacity: 1;
        transform: rotate(0deg) scale3d(1, 1, 1);
    }
}

@keyframes s-brand-in {
    from {
        opacity: 0;
        transform: translateY(-20%);
    }
    to {
        opacity: 1;
        transform: translateY(0%);
    }
}

@keyframes s-tech-in {
    from {
        opacity: 0;
        transform: translateY(20%);
    }
    to {
        opacity: 1;
        transform: translateY(0%);
    }
}

@keyframes s-business-in {
    from {
        opacity: 0;
        transform: translateX(-20%);
    }
    to {
        opacity: 1;
        transform: translateX(0%);
    }
}

@keyframes s-us-in {
    from {
        opacity: 0;
        transform: translateX(20%);
    }
    to {
        opacity: 1;
        transform: translateX(0%);
    }
}

@keyframes s-lines-in {
    from {
        stroke-dashoffset: 1730;
    }
    to {
        stroke-dashoffset: 2000;
    }
}


.services {
    @include grid-column(12);
}

.services__service {
    @include grid-column(12);
        margin-bottom: 2rem;
    @include breakpoint(medium) {
        @include grid-column(6);
    }
    @include breakpoint(large) {
        @include grid-column(4);
    }
}

.services__icon {
    @include grid-column(3, 0);
    padding-right: 1rem;
    svg {
        path {
            transition: .2s stroke ease-out;
        }
    }
    &:hover {
        svg {
            path {

                stroke: $brand-white;
            }
        }
    }
}

.services__content {
    @include grid-column(9, 0) text-align: left;
}

.services__name {
    font-size: 1.4rem;
    line-height: 1rem;
    margin-bottom: 0;
    a {
        transition: .2s color ease-out;
        color: $brand-white;
        &:hover {
            color: $brand-green;
        }
    }
}

.services__description {
    p {
        font-size: .8rem;
        line-height: 1rem;
        margin-bottom: 0;
    }
}