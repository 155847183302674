.hero-section {
    margin-bottom: 0px;
    position: relative;
    z-index: 0;
}

.hero {
    overflow: hidden;
    background-color: $brand-green; //    background-size: 200% 200%;
    //   animation: foo 2.5s ease;
    @include breakpoint(medium) {
        padding-top: 24rem;
    }
}

.hero__content {

    z-index: 5;
    top: 1rem;
    position: relative;
    @include breakpoint(small) {
        margin-bottom: 10rem;
        height: 12rem;
        margin-top: .5rem; //  width: 80%;
        margin-left: auto;
        margin-right: auto;
    }
    @include breakpoint(medium) {
        position: absolute;

        width: 100%;
    }
    @include breakpoint(large) {
        margin-bottom: 0rem;
    }
    @include breakpoint(xxlarge) {
        left: 50%;
        transform: translateX(-50%)
    }
}


.hero__featured-work {
    @include grid-column(12);
    @include breakpoint(large) {
        @include grid-column(10);
        @include grid-column-position(center);
    }
}

.featured-work__image {
    @include grid-column(12);
    @include breakpoint(medium) {
        @include grid-column(6);
        @include grid-column-position(6);
    }
    img {
        transition: transform .5s ease-in-out;
    }
    &:hover {
        img {
            transform: scale(1.05);
        }
    }
}

.featured-work__text {
    padding-top: 2rem;
    @include grid-column(12);
    @include breakpoint(medium) {
        @include grid-column(6);
        @include grid-column-position(-6);
    }
}

.featured-work__headline {
    @include breakpoint(medium) {
        width: 120%;
    }
    border-bottom: 2px solid #0c182c;
    margin-bottom: 1rem;
    padding-left: .2rem;
}

.featured-work__title {
    font-size: 2.4rem;
    line-height: 2.4rem;
    padding-left: .2rem;
}

.featured-work__copy {
    color: #0c182c;
    padding-left: .2rem;
    @include breakpoint(small only) {
        color: $brand-white;
    }
}

.hero__scroller {
    position: relative;
    height: 0px;
    transition: transform .3s ease;
    text-align: center;
    font-family: $header-font-family;
    &:hover {
        transform: translateY(5px);
    }
    top: 1rem;
    @include breakpoint(medium) {
        top: 5rem;
    }
    @include breakpoint(large) {
        top: 4rem;
    }
    a {
        display: inline-block;
    }
}


.scroller__arrow {
    width: 1.2rem;
    @include breakpoint(medium) {
        width: 2rem;
    }
    display: inline-block;
}

.scroller__text {
    font-weight: 700;
    color: $brand-white;
    font-size: 1.1rem;
    p {
        margin-bottom: .5rem;
    }
}


.hero1 {
    animation: hero1 .75s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    animation-fill-mode: forwards;
    animation-delay: 2s;
    opacity: 0;
}

@keyframes hero1 {
    0% {
        opacity: 0;
        transform: translateX(-30%);
    }

    100% {
        opacity: 1;
        transform: translateX(-0%);
    }
}

.hero2 {
    animation: hero23 .75s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    animation-fill-mode: forwards;
    animation-delay: 2.1s;
    opacity: 0;
}


@keyframes hero23 {
    0% {
        opacity: 0;
        transform: translateX(-30%);
    }

    100% {
        opacity: 1;
        transform: translateX(-0%);
    }
}


.hero3 {
    animation: hero23 .75s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    animation-fill-mode: forwards;
    animation-delay: 2.2s;
    opacity: 0;
}

.hero4 {
    animation: hero4 .75s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    animation-fill-mode: forwards;
    animation-delay: 2.3s;
    opacity: 0;
}

@keyframes hero4 {
    0% {
        opacity: 0;
        transform: translateX(30%);
    }

    100% {
        opacity: 1;
        transform: translateX(-0%);
    }
}

.hero5 {
    animation: hero56 .75s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    animation-fill-mode: forwards;
    animation-delay: 4s;
    opacity: 0;
}

.hero6 {
    animation: hero56 .75s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    animation-fill-mode: forwards;
    animation-delay: 4s;
    opacity: 0;
}

@keyframes hero56 {
    0% {
        opacity: 0;
        transform: translateY(-30%);
    }

    100% {
        opacity: 1;
        transform: translateY(-0%);
    }
}


.hero__slider {
    @include grid-column(7);
    @include grid-column-position(center);
    max-width: 520px;
    @include breakpoint(medium) {
        @include grid-column(8);
        @include grid-column-position(center);
    }
    @include breakpoint(large) {
        @include grid-column(5);
        @include grid-column-position(center);
    }
}

.hero__slider-title {
    font-size: 1.2rem;
    display: block;
    @include breakpoint(small only) {
        margin-bottom: .2rem;
        font-size: .9rem;
    }
}

.hero__gallery {
    background-image: url(/_assets/img/wood_slide.png);
    background-repeat: no-repeat;
    background-position: center;
    @include breakpoint(small only) {
        background-size: 100% 80%;
    }
}

.hero-gallery__slide {
    outline: none;
}

.slide__image {

    @include grid-column(10);
    @include grid-column-position(center);
    @include breakpoint(small only) {
        margin-bottom: .3rem;
    }
    @include breakpoint(medium) {
        @include grid-column(4);
    }
    img {
        transition: .5s transform ease-out;
    }
    &:hover {
        img {
            transform: scale(1.1);
        }
    }
}

.slide__text {
    @include breakpoint(small only) {
        margin-bottom: 3rem;
    }
    @include grid-column(12);
    text-align: center;
    @include breakpoint(medium) {
        @include grid-column(8);
        text-align: left;
    }
    padding-left: 0 !important;
}

.slide__title {
    font-size: 1.2rem;
    line-height: 1.2rem;
    @include breakpoint(medium) {
        font-size: 1.5rem;
        line-height: 1.5rem;
    }
    margin-bottom: 0;
    a {
        color: $brand-white;
    }
}

.slide__link {
    font-weight: bold;
    text-transform: uppercase;
    font-size: .8rem;
    @include breakpoint(medium) {
        font-size: .9rem;
    }
}

.equalize-vert-center {
    @include breakpoint(medium) {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
    }
}

.hero-slider-prev {
    &:before {
        display: none;
    }
    svg {
        transform: rotate(90deg);
    }
}

.hero-slider-next {
    &:before {
        display: none;
    }
    svg {
        transform: rotate(-90deg);
    }
}