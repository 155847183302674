.tagline {
    text-align: center;
}

.tagline__taglines {
    margin-bottom: 1rem;
    @include breakpoint(medium){
    margin-bottom: 3rem;
}
}

.tagline__logo {
    width: 5rem;
    display: inline-block;
    @include breakpoint(medium){
    margin-top: 2rem;
}
    path {
        fill: #1C4236;
    }
    @include breakpoint(medium) {
        margin-top: 3rem;
    }
}

.tagline__headline {
    line-height: 1.5rem;
    @include breakpoint(small only) {
        margin-bottom: .2rem;
    }
    @include breakpoint(medium) {
        line-height: 3rem;
    }
}


//@include mui-series(2.5s) {
// 2 second shake
//.tagline1{ @include mui-queue(1s, -.2s, fade(in),slide(in,down)); }
.tagline1 {
    @include mui-animation(fade(in), slide(in, down));
    animation-duration: 2s;
    animation-delay: 2.5s;
    opacity: 0;
    animation-fill-mode: forwards;
}

.tagline2 {
    @include mui-animation(fade(in), slide(in, up, 20%));
    animation-duration: 2s;
    animation-delay: 3.5s;
    opacity: 0;
    animation-fill-mode: forwards;
}

.tagline3 {
    @include mui-animation(fade(in), slide(in, up, 20%));
    animation-duration: 2s;
    animation-delay: 4s;
    opacity: 0;
    animation-fill-mode: forwards;
}

.tagline4 {
    @include mui-animation(fade(in), slide(in, up, 20%));
    animation-duration: 2s;
    animation-delay: 4.5s;
    opacity: 0;
    animation-fill-mode: forwards;
}

.tagline5 {
    @include mui-animation(fade(in), slide(in, down));
    animation-duration: 2s;
    animation-delay: 6.8s;
    opacity: 0;
    animation-fill-mode: forwards;
}

.tagline6 {
    @include mui-animation(fade(in), slide(in, down));
    animation-duration: 2s;
    animation-delay: 6.9s;
    opacity: 0;
    animation-fill-mode: forwards;
}

.tagline7 {
    @include mui-animation(fade(in), slide(in, down, 20%));
    animation-duration: 2s;
    animation-delay: 5.6s;
    opacity: 0;
    animation-fill-mode: forwards;
}