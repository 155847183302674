.cta {
    background-color: $body-background;
    position: relative;
    z-index: 3;
    @include breakpoint(small only){
        padding-top: 6rem;
    }
}

.work-with-us {
    @include grid-column(12);
    text-align: center;
    margin-bottom: 5rem;
    @include breakpoint(medium) {
        padding-top: 5rem;
        @include grid-column(6);
    }
}

.services__wrap {
    @include grid-row;
    @include grid-column(12,0);
}
.services {
    @include grid-layout(2, '.service');
    @include breakpoint(large) {
        @include grid-layout(3, '.service');
    }
    margin-top: 1.3rem;
    margin-bottom: 2.1rem;
    @include breakpoint(medium) {
        margin-bottom: 3.7rem;
    }
}

.service {
    text-align: left;
    @include breakpoint(small only){
       margin-bottom: 1rem;
    }
    margin-bottom: .5rem;
    a:hover {
        path{

            stroke: $brand-white;
            }
            span {
             color: $brand-green;
            }
    }
    &__icon {
        width: 2rem;
        display: inline-block;
        path {

        transition: .1s stroke ease-in-out;
        }
    }
    &__name {
        vertical-align: top;
        margin-top: .1rem;
        margin-left: .3rem;
        display: inline-block;
        color:$brand-white;
        transition: .1s color ease-in-out;
        @media only screen and (max-device-width: 320px) {
            //Iphone 5 specific sizing
            font-size: .7rem;
        }
        font-size: .9rem;
        @include breakpoint(xlarge) {
            font-size: 1rem;
            letter-spacing: 0px;
        }
    }
}

.brand-story {
    @include grid-column(12);
    text-align: center;
    background-image: url(/_assets/img/wood_transparent.png);
    padding-top: 2rem;
    @include breakpoint(medium) {
        padding-top: 5rem;
        @include grid-column(6);
    }
}

.brand-story__copy {
    padding: 1rem;
    @include breakpoint(medium) {
        padding-left: 2rem;
    }
    margin-bottom: 0;
    font-size: 1.1rem;
    text-align: left;
}
