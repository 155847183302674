@import 'foundation';
@import 'motion-ui';
@import 'font-awesome';
@import "../../../bower_components/slick-carousel/slick/slick.scss";
@import "../../../bower_components/slick-carousel/slick/slick-theme.scss";

@import "modules/all";

@include motion-ui-transitions;

@include foundation-global-styles;
//@include foundation-xy-grid-classes;
@include foundation-grid;
@include foundation-typography;
@include foundation-forms;
@include foundation-button;
//@include foundation-accordion;
//@include foundation-accordion-menu;
//@include foundation-badge;
//@include foundation-breadcrumbs;
//@include foundation-button-group;
//@include foundation-callout;
//@include foundation-card;
@include foundation-close-button;
//@include foundation-menu;
//@include foundation-menu-icon;
//@include foundation-drilldown-menu;
//@include foundation-dropdown;
//@include foundation-dropdown-menu;
//@include foundation-responsive-embed;
//@include foundation-label;
//@include foundation-media-object;
@include foundation-off-canvas;
//@include foundation-orbit;
//@include foundation-pagination;
//@include foundation-progress-bar;
//@include foundation-slider;
//@include foundation-sticky;
//@include foundation-reveal;
//@include foundation-switch;
//@include foundation-table;
//@include foundation-tabs;
//@include foundation-thumbnail;
//@include foundation-title-bar;
//@include foundation-tooltip;
//@include foundation-top-bar;
//@include foundation-visibility-classes;
//@include foundation-float-classes;
@import "partials/all";



@font-face {
    font-family: 'NexaBlack';
    src: url('/_assets/fonts/nexablack-webfont.eot');
    src: url('/_assets/fonts/nexablack-webfont.eot?#iefix') format('embedded-opentype'), url('/_assets/fonts/nexablack-webfont.woff2') format('woff2'), url('/_assets/fonts/nexablack-webfont.woff') format('woff'), url('/_assets/fonts/nexablack-webfont.ttf') format('truetype'), url('/_assets/fonts/nexablack-webfont.svg#nexa_blackregular') format('svg');
    font-weight: normal;
    font-style: normal;
}
::selection {
  background-color: rgba(85,167,0,1);
  color: white;
}
section {
        margin-bottom: 5rem;
    @include breakpoint(medium) {
        margin-bottom: 10rem;
    }
}

.section--last {
    margin-bottom: 0rem !important;
}

//needed to can't see trees if you load in on a scrolled position
.bg-wrap {
    background-color: $body-background;
    position: relative;
    //EWWWWWW
    top: -8px;
}

.internal-page {
    .bg-wrap {
        top: 0;
    }
}
//Logic for openmening menu by itself without main nav

/*
.off-canvas:not(.is-open) + .panel--work.is-open {
    @include breakpoint(medium) {
        -webkit-transform: translate(0px, 0);
        transform: translate(0px, 0);
    }
}
*/

///////////////
// ANIMATION //
///////////////
.wow {
visibility: hidden;
}

.myFadeIn {
    @include mui-animation(fade(in));
    animation-duration: 1s;
}

.slideInLeft {
    @include mui-animation(fade, slide(in, left, 20%));
     animation-duration: 1s;
}

.slideInRight {
    @include mui-animation(fade, slide(in, right, 20%));
    animation-duration: 1s;
}

.slideInDown {
    @include mui-animation(fade, slide(in, down, 20%));
    animation-duration: 1s;
}

.slideInUp {
    animation-name: foo;
    animation-duration: 1s;
    animation-fill-mode: forwards;
}
.workSlideInUp {
    animation-name: workSlideIn;
    animation-duration: 1s;
    animation-fill-mode: forwards;
}

.slideInUp--no-delay {
  @include mui-animation(fade, slide(in, up, 20%));
    animation-duration: 1s;
}

.slideOutUp {
    animation-name: slideOutUp;
    animation-duration: 1s;
    animation-fill-mode: forwards;

}

.slideInDown--no-fade {
    animation-name: slideInDown--no-fade;
    animation-duration: 2s;
}

@keyframes slideInDown--no-fade {
    0% {
        transform: translateY(-100%);
    }
    75% {
        transform: translateY(-100%);
    }
    100% {
        transform: translateY(0%);
    }
}

@keyframes foo {
    0% {
        transform: translateY(100%);
    }
    100% {
        transform: translateY(0%);
    }
}

@keyframes workSlideIn{
    0% {
        transform: translateY(20%);
        opacity: 0;
    }
    100% {
        transform: translateY(0%);
        opacity: 1;
    }
}

@keyframes slideOutUp{
    0% {
        transform: translateY(0%);
    }
    100% {
        transform: translateY(-100%);
    }
}

.slideInLeft--slow {
    animation-name: custom-13;
    animation-duration: 1s;
    @include breakpoint(medium) {
        animation-name: slideInLeft--slow;
    animation-duration: 2s;
    }
}

@keyframes slideInLeft--slow {
    0% {
        opacity: 0;
        transform: translateX(20%);
    }
    50% {
        opacity: 0;
        transform: translateX(20%);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

.slideInRight--slow {
    animation-name: custom-15;
    animation-duration: 1s;
    @include breakpoint(medium) {
        animation-name: slideInRight--slow;
    animation-duration: 2s;
    }
}

@keyframes slideInRight--slow {
    0% {
        opacity: 0;
        transform: translateX(-20%);
    }
    50% {
        opacity: 0;
        transform: translateX(-20%);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

.slideInDown--slow {
    animation-name: custom-17;
    animation-duration: 1s;
    @include breakpoint(medium) {
        animation-name: slideInDown--slow;
    animation-duration: 2s;
    }
}

@keyframes slideInDown--slow {
    0% {
        opacity: 0;
        transform: translateY(-20%);
    }
    50% {
        opacity: 0;
        transform: translateY(-20%);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}


.app-vid {
    background: #1d2029 url(images/go-outside/iphone.png) center top no-repeat;
    background-size: contain;
    height: 100%;
    width: 100%;
    text-align: center;
    padding: 4.6rem 0.6rem 7.3rem .2rem;
    @include breakpoint(medium) {
        padding: 6.9rem 0.9rem 11.1rem 0.2rem;
    }
    @include breakpoint(large) {
        padding: 6.9rem 5.9rem 11.1rem 5.1rem;
    }
}

video {
    height: auto;
    width: 200px;
    @include breakpoint(medium) {
        width: 300px;
    }


}


.internal-page {

    @keyframes slideInDown--no-fade {
        0% {
            transform: translateY(-100%);
        }
        25% {
            transform: translateY(-100%);
        }
        100% {
            transform: translateY(0%);
        }
    }
}
