.contact-page {
     background-color: #0c182c;
    background-image: url(/_assets/img/wood_transparent.png);
}

.contact__content {
    @include breakpoint(small only) {
        @include grid-column-collapse;
    }
    @include grid-column(12);
    @include breakpoint(large) {
        @include grid-column(11);
        @include grid-column-position(center);
        margin-top: 3rem;
    }
    @include breakpoint(xlarge) {
        margin-top: 5rem;
    }
}

.bg_wrap {
    @include breakpoint(large) {
        background: linear-gradient(to bottom, #5aa700 5%, #0a4d59 60%);
        background-position: 77px 0px;
    background-repeat: no-repeat;
    }
}

.button--contact {
    @include grid-column(12);
    @include breakpoint(large) {
        @include grid-column(10);
    }
    margin-bottom: .5rem;
    text-align: left;
}

.contact__logo {
    @include grid-column(12);
    text-align: center;
    svg {
        width: 30%;
    }
    padding-top: 2rem;
    margin-bottom: 1.5rem;
    @include breakpoint(medium) {
        margin-bottom: 2rem;
    }
    @include breakpoint(large) {
        background-repeat: no-repeat;
        background-position: 77px 0px;
        @include grid-column(4);
        padding-top: 5rem;
        svg {
            width: 100%;
        }
    }
    @include breakpoint(xlarge) {
        background-position: 90px 0px;
    }
    path {
        fill: $brand-green;
    }
}

.contact__form {
    h3 {
        margin-bottom: 2rem;
        @include breakpoint(small only) {
            font-size: 1.6rem;
            text-align: center;
        }
    }
    p {
        margin-bottom: 1rem;
    }
    @include grid-column(9);
    @include breakpoint(small only) {
        @include grid-column-position(center);
        background: linear-gradient(to bottom, #5aa700 5%, #0a4d59 60%);
    }
    padding-top: 2rem;
    form {
        padding: 1rem;
    }
    @include breakpoint(medium only) {
        @include grid-column(10);
        background: linear-gradient(to bottom, #5aa700 5%, #0a4d59 60%);
        @include grid-column-position(center);
    }
    @include breakpoint(large) {
        padding-top: 3rem;
        form {
            padding-right: 4rem;
        }
        @include grid-column(8);
    }
    input:not([type="submit"]) {
        @include breakpoint(medium) {
            margin-top: 1rem;
        }
        margin-bottom: 2rem;
    }
    input[type="text"] {
        background-color: transparent;
        border: none;
        border-bottom: 2px solid $brand-green;
        box-shadow: none;
        color: $brand-white;
        font-size: 1.5rem;
        &:focus {
            border-bottom: 2px solid $brand-white;
        }
    }
    input[type="email"] {
        background-color: transparent;
        border: none;
        border-bottom: 2px solid $brand-green;
        &:focus {
            border-bottom: 2px solid $brand-white;
        }
        box-shadow: none;
        color: $brand-white;
        font-size: 1.5rem;
    }
    select {
        background-color: transparent;
        border: none;
        padding-left: 0rem;
        border-bottom: 2px solid $brand-green;
        &:focus {
            border-bottom: 2px solid $brand-white;
        }
        box-shadow: none;
        color: $brand-white;
        margin-bottom: 3rem;
    }
}

.contact__thanks {
    text-align: center;
    padding-bottom: 2rem;
}

.is-invalid-input {
    border-bottom: 2px solid #8a2e22 !important;
}

.is-invalid-input:not(:focus) {
    background-color: transparent;
}

.form-error {
    color: #8a2e22;
}

.contact__label {
    color: $brand-white;
}
