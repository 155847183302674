//Active nav item highlight
.panel--contact.is-open ~ .panel--menu {
    .nav__item4 {
        background-position: 100% 50%;
    }
}

.panel--contact {
    background: linear-gradient(to bottom, #5aa700 5%, #0a4d59 60%);
    padding-bottom: 3rem;
}

.contact-panel__header {
    @include grid-column(11);
    @include grid-column-position(center);
    h3 {
        margin-bottom: 0;
        line-height: 2rem;
    }
    p {
        margin-bottom: 1rem;
    }
    margin-top: 5rem;
}

.contact-panel__form {
     p {
        margin-bottom: 1rem;
    }
    @include grid-column(11);
    @include grid-column-position(center);

}
