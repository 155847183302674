.button {
    font-family: $header-font-family;
    padding: .85rem 1rem 0.70rem 1rem;
    &:hover {
        color: $brand-green;
        background-color: $brand-white;
    }
}

.button-group {
    animation-fill-mode: both;
}

.button--light {
    background-color: $brand-white;
    color:$brand-green;
    &:hover {
background-color: #175543;
color: $brand-white;
    }
}

.button-group__text {
    font-family: $header-font-family;
    top: 0.4rem;
    position: relative;
    display: inline-block;
    width: 0px;
    font-size: 1rem;
    margin-right: .5rem;
    color: $brand-white;
    &--portfolio {
        color: $brand-green;
    }
}

.button-group__button {
    cursor: pointer;
    background-color: $brand-green;
    transition: background-color .5s ease-in-out, transform .5s ease-in-out;
    outline: none;
    padding: 1.5rem;
    border-radius: 100px;
    span {
        background-color: #f3ffe4;
        display: block;
        width: 18px;
        height: 5px;
        position: absolute;
        transform: translate(-50%, -50%);
        transition: background-color .5s ease-in-out;
    }
    span:last-of-type {
        transform: translate(-50%, -50%) rotate(90deg);
    }
    &:hover {
        @include breakpoint(medium) {
            background-color: $brand-white;
            span {
                background-color: $brand-green;
            }
        }
    }
}

.button-group__button--top-nav {
    background-color: $brand-white;
    span {
        background-color: $brand-green;
    }
    &:hover {
        background-color: #175543;
        span {
            background-color: $brand-white;
        }
    }
}

.button-group__text--close {
    @include mui-animation(fade(out));
    opacity: 0;
    animation-duration: 1s;
    right: 3rem;
    @include breakpoint(small only) {
        display: none;
    }
}

.button-group__text--more {
    opacity: 1;
    @include mui-animation(fade(in));
    animation-duration: 1s;
    position: relative;
    right: 2.7rem;
    @include breakpoint(medium) {
        right: 2rem;
    }
}

.button--large {
    text-transform: uppercase;
    font-size: 1.2rem;
    padding: .6rem 1.6rem;
}

//effects when menu opens
.menu-open {
    .button-group__text--more {
        opacity: 0;
        @include mui-animation(fade(out));
    }
    .button-group__text--close {
        opacity: 1;
        @include mui-animation(fade(in));
    }
    .button-group__button {
        background-color: #175543;
        transform: rotate(-135deg);
        &:hover {
            @include breakpoint(large) {
                background-color: $brand-white;
                span {
                    background-color: #175543;
                }
            }
        }
    }

    .button-group__button--top-nav {
        span {
            background-color: $brand-white;
        }
    }
}