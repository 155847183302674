.post-hero__wrapper {
    background-image: url(/_assets/img/taps.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    overflow: hidden;
    width: 100%;
    position: relative;
    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-image: linear-gradient(to bottom right, $brand-green, $brand-green);
        opacity: .9;
        height: 100vh;
        z-index: 0;
    }
}

.post-hero {
    @include grid-row;
    @include breakpoint(medium) {}
}

.post-hero__content {
    @include grid-column(12);
    @include breakpoint(medium) {
        @include grid-column(10);
        @include grid-column-position(center);
    }
    text-align: center;
    padding-top: 3rem;
    padding-bottom: 5rem;
    position: relative;
    z-index: 1;
    @include breakpoint(medium) {
        padding-top: 10rem;
        padding-bottom: 10rem;
    }
    @include breakpoint(xlarge) {
        padding-bottom: 19rem;
        h1 {
            position: fixed;
            width: 500px;
            width: 80%;
            left: 50%;
            transform: translateX(-50%);
        }
    }
}

.post__content {
    padding-top: 2rem;
    @include breakpoint(medium) {

    padding-top: 5rem;
    }
    position: relative;
    z-index: 1;
    background-color: white;
    color: $brand-gray;
    @include grid-column(11);
    @include grid-column-position(center);
}

.portfolio--news {
    @include breakpoint(small only) {
        .portfolio-header__other-work {
            z-index: 5;
            position: relative;
            padding: .5rem;
            top: 1rem;
            background-color: white;
        }
        footer {
            padding-top: 0rem;
        }
    }
    @include breakpoint(medium) {
        .portfolio-header__other-work {
            position: fixed;
            top: 1.2rem;
            right: 1rem;
            z-index: 5;
        }
    }
    p {
        @include breakpoint(small only) {
            margin-bottom: 1rem;
        }
    }
    header {
        padding-bottom: 0;
    }
}

.portfolio-header--post {
    @include breakpoint(small only) {
        display: none;
    }
}

.news__header {
    border-bottom: 1px solid $brand-green;
    margin-bottom: 1rem;
}
