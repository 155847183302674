
.process-graphic {
    .logo1 {
        animation: 1s p-logo1-in cubic-bezier(0.25, 0.46, 0.45, 0.94);
        animation-fill-mode: both;
        animation-delay: 1s;
    }
    .logo2 {
        animation: 1s p-logo2-in cubic-bezier(0.25, 0.46, 0.45, 0.94);
        animation-fill-mode: both;
        animation-delay: 1.2s;
        transform-origin: center;
    }
    .logo3 {
        animation: 1s p-logo3-in cubic-bezier(0.25, 0.46, 0.45, 0.94);
        animation-fill-mode: both;
        animation-delay: 1.4s;
        transform-origin: center;
    }
   .logo3-2, {
        animation: 0s p-logo3-2-in cubic-bezier(0.25, 0.46, 0.45, 0.94);
        animation-fill-mode: both;
        animation-delay: 2.4s;
        transform-origin: center;
    }
    .logo4 {
        animation: .6s p-logo4-in cubic-bezier(0.25, 0.46, 0.45, 0.94);
        animation-fill-mode: both;
        animation-delay: 2.3s;

    }
}


@keyframes p-logo1-in {
    from {
        opacity: 0;
        transform: translateY(-50%);
    }
    to {
        opacity: 1;
        transform: translateY(0%);
    }
}

@keyframes p-logo2-in {
    from {
        opacity: 0;
        transform: translateX(-30%);
    }
    to {
        opacity: 1;
        transform: translateY(0%);
    }
}

@keyframes p-logo3-in {
    from {
        opacity: 0;
        transform: translateY(30%);
    }
    to {
        opacity: 1;
        transform: translateX(0%);
    }
}

@keyframes p-logo3-2-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes p-logo4-in {
    from {
        opacity: 0;
        transform: translateY(-30%);
    }
    to {
        opacity: 1;
        transform: translateY(0%);
    }
}


.process-body__header {
    @include grid-column(12);
    margin-top: 5rem;
    @include breakpoint(medium) {
    margin-top: 0rem;
        @include grid-column(7, 0);
        @include grid-column-position(center);
    }
    text-align: center;
    position: relative;
    &--first {
        margin-top: 0;
    }
}


.process-body-header__logo {
    path {
        fill: $brand-green;
    }
    svg {

        width: 3rem;
    }
}

.process-body-header__content {
    position: relative;
}

.process-body-header__title {
    font-size: 4rem;
    line-height: 3rem;
    margin-bottom: 0;
    background-color: #0c182c;
    display: inline-block;
    padding: 1rem;
    position: relative;
    z-index: 2;
}

.process-body-header__line {
    @include breakpoint(small only) {
        display: none;
    }
    background-color: $brand-green;
    height: 7px;
    width: 101%;
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
}

.process-body__block {
    @include grid-column(12);
    @include breakpoint(medium) {
        @include grid-column(5);
    }
    text-align: center;
    background-image: url(/_assets/img/wood_transparent.png);
    position: relative;
    margin-bottom: 1rem;
}

.process-body-block__content {
        padding: 2rem 1rem;
    @include breakpoint(medium) {
        padding: 4rem 2rem;
    }
}

.process-body-block__line {
    @include breakpoint(small only) {
        display: none;
    }
    display: inline-block;
    background-color: $brand-green;
    width: 6px;
    height: 85px;
    position: absolute;
    top: -37px;
    &::after {
        content: "";
        display: block;
        height: 16px;
        width: 16px;
        border-radius: 100px;
        background-color: $brand-green;
        position: absolute;
        bottom: -1px;
        left: -5px;
    }
}

.process-body-block__title {
    color: $brand-green;
    text-transform: uppercase;
}

.process-body-block__copy {}


.process-body__seperator {
    @include grid-column(2);
    text-align: center;
}

.process-body__vert-line {
    display: inline-block;
    background-color: $brand-green;
    width: 6px;
    height: 100%;
}