.dribbble__wrapper {
    @include breakpoint(medium) {
        &::after {
            content: "";
            background: url(/_assets/img/bottom-trees.png) repeat-x bottom;
            @include breakpoint(large) {
                background-size: contain;
            }
            height: 200px;
            width: 100%;
            top: 30px;
            display: inline-block;
            position: relative;
            image-rendering:pixelated;
        }
    }
    p {
        margin-bottom: 2rem;
    }
}

.dribbble {
    @include grid-column(12);
}

.dribbble__text {
    @include grid-column(12);
    padding: 1rem;
    @include breakpoint(medium) {
        @include grid-column(6);
        @include grid-column-position(1);
    }
}

.shots__wrapper {
    @include grid-column(12);
    @include breakpoint(medium) {
        height: 120px;
        @include grid-column(7);
        @include grid-column-position(5);
    }
    @include breakpoint(large) {
        height: 250px;
    }
}

.shots {
    position: relative;
    @include breakpoint(medium) {
        right: -45rem;
        top: -23rem;
    }
    .shot {
        padding: .5rem;
        @include breakpoint(small only){
            display: none;
        }
    }
    .shot--mobile {
       display: block;
    }
    @include grid-layout(2, '.shot');
    @include breakpoint(medium) {
        transform: rotate3d(0, 1, 0, 15deg) rotate3d(1, 0, 0, 45deg) rotate3d(0, 0, 1, 55deg);
        .shot {
            img {
                transition: all .3s ease-in-out;
            }
        }
        .shot:hover {
            img {
                transform: scale3d(1.2, 1.2, 1);
            }
        }
    }
    @include breakpoint(large) {
        right: -60rem;
        top: -24rem;
    }
    @include breakpoint(xlarge) {
        right: -70rem;
        top: -33rem;
    }
}
