.hero-section--us {
    background-color: $brand-green;
}

.us-hero {
    background-image: url(/_assets/img/bottom-trees-short.png);
    background-repeat: repeat-x;
    background-position: bottom;
    background-size: auto 40px;
}

.us-hero__content {
    position: relative;
    z-index: -1;
    @include grid-column(12);
    padding-top: 2rem;
    padding-bottom: 3rem;
    @include breakpoint(medium) {
        @include grid-column(9);
        @include grid-column-position(center);
        padding-top: 6rem;
        padding-bottom: 5rem;
    }
    &--process {
        @include grid-column(8);
        @include grid-column-position(center);
        padding-top: 2rem;
        padding-bottom: 3rem;
        @include breakpoint(medium) {
            @include grid-column(5);
            @include grid-column-position(center);
        padding-top: 5rem;
            padding-bottom: 3rem;
        }
    }
    &--services {
        padding-bottom: 6rem;
        padding-bottom: 4rem;
        @include breakpoint(medium) {
            @include grid-column(6);
            @include grid-column-position(center);
            padding-top: 5rem;
            padding-bottom: 6rem;
        }
    }
    &--culture {
        @include grid-column(7);
        @include grid-column-position(center);
        @include breakpoint(medium) {
            @include grid-column(4);
            @include grid-column-position(center);
        }
        padding-top: 2rem;
        padding-bottom: 5rem;
        z-index: 1;
    }
}

.us-content {

   margin-top: 1rem;
    padding-bottom: 10rem;
    text-align: center;
}


.us-content__header {
    display: inline-block;
    @include grid-column(11);
    @include grid-column-position(center);
    text-align: center;
    @include breakpoint(medium) {
        @include grid-column(7);
        @include grid-column-position(center);
    }
}

.us-content__header-image {
    @include grid-column(12);
    @include breakpoint(small only) {
        display: none;
    }
    @include breakpoint(medium) {
        @include grid-column(4);
        @include grid-column-position(-8)
    }
}

.us-content__header-content {
    @include grid-column(12);
    @include breakpoint(medium) {
        @include grid-column(8);
    }
    &--swap {
        @include breakpoint(medium) {
            @include grid-column-position(4)
        }
    }
}

.us-content__header--culture {
    margin-bottom: 2rem;
    @include breakpoint(medium) {
        margin-bottom: 5rem;
        margin-top: 2rem;
        text-align: left;
        @include grid-column(10);
        @include grid-column-position(center);
    }
}

.us-content-header {
    &__copy {
        margin-bottom: 5rem;
        @include breakpoint(small only) {
            text-align: left;
        }
        &--culture {
            margin-bottom: 2rem;
        }
    }
}

.us-content-header__title {
    @include breakpoint(small only) {
        font-size: 2.3rem;
        line-height: 2.3rem;
    }
    &--culture {
        margin-bottom: 2rem;
    }
}


.us-content__body {
    @include grid-column(11);
    @include grid-column-position(center);
    @include breakpoint(medium) {
        @include grid-column(11);
        @include grid-column-position(center);
    }
    &--culture {
        text-align: left;
        @include breakpoint(medium) {
            @include grid-column(6);
            @include grid-column-position(center);
        }
    }
}


.belief {
    position: relative;
    overflow: hidden;
    margin-bottom: 1rem;
    &:not(:last-of-type) {
        padding-bottom: 5rem;
    }
    &__number-wrap {
        background-color: $brand-green;
        display: block;
        border-radius: 25% 25% 100% 100%;

        text-align: center;
        width: 60px;
        height: 60px;

        margin-bottom: 2rem;
    }

    &__number {
        line-height: 23px;
        padding-top: 15px;
        display: inline-block;
        margin-bottom: 0;
    }
    &__line {
        position: absolute;
        width: 6px;
        height: 100%;
        background-color: $brand-green;
        left: 1.7rem;
        top: 4.5rem;
        z-index: 1;
    }
    &__text {
        position: relative;
        display: inline-block;
        background-color: #0c182c;
        z-index: 2;
        font-size: 2.6rem;
        line-height: 2.5rem;
        @include breakpoint(large) {
            font-size: 3.6rem;
            line-height: 3.5rem;
        }
        @include breakpoint(xlarge) {
            font-size: 4.6rem;
            line-height: 4.5rem;
        }
        padding: 1rem 0rem;
    }
}


.button--us {
    margin-top: 4rem;
}

.other-us {
    @include grid-column(12);
    @include breakpoint(large) {
        @include grid-column(8);
        @include grid-column-position(center);
    }
    .other-project {
        @include grid-column(12);
        @include breakpoint(medium) {
            @include grid-column(4);
        }
        .u-wrap {
            margin-bottom: 1rem;
        }
        text-align: center;
        .project__title {
            margin-bottom: 0;
            color: $brand-white;
            line-height: 1.5rem;
            @include breakpoint(small only) {
                font-size: 2rem;
            }
        }
    }
}