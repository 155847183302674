.slide-up {
    slide-up {
        -webkit-animation-duration: 1s;
        -moz-animation-duration: 1s;
        -o-animation-duration: 1s;
        animation-duration: 1s;
        -webkit-animation-fill-mode: both;
        -moz-animation-fill-mode: both;
        -o-animation-fill-mode: both;
        animation-fill-mode: both;
        animation-name: SlideUp;
    }
    @keyframes SlideUp {
        0% {
            transform: translate3d(0, 100%, 0);
        }
        100% {
            transform: none;
            /*-moz-transform: translate3d(0,0,0);
        -ms-transform: translate3d(0,0,0);
        -o-transform: translate3d(0,0,0);
        -webkit-transform: translate3d(0,0,0);
        transform: translate3d(0,0,0);*/
        }
    }
    /*treeline animation*/
    .treeline#wayback.slide-up {
        animation-delay: 1s;
    }
    .treeline#midback.slide-up {
        animation-delay: 1.1s;
    }
    .treeline#midfront.slide-up {
        animation-delay: 1.2s;
    }
    .front-tree.slide-up {
        animation-delay: 1.3s;
    }
    animation-duration: 1s;
    animation-fill-mode: both;
    animation-name: SlideUp;
}

@keyframes SlideUp {
    0% {
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }
    100% {
        -webkit-transform: none;
        transform: none;
        /*-moz-transform: translate3d(0,0,0);
        -ms-transform: translate3d(0,0,0);
        -o-transform: translate3d(0,0,0);
        -webkit-transform: translate3d(0,0,0);
        transform: translate3d(0,0,0);*/
    }
}


/*treeline animation*/

.treeline#midfront.slide-up {
    animation-delay: 1.5s;
}

.treeline#midback.slide-up {
    animation-delay: 1.7s;
}

.treeline#wayback.slide-up {
    animation-delay: 1.9s;
}

.front-tree.slide-up {
    animation-delay: 1.8s;
}

.trees {
    background: none;
    position: absolute;
    bottom: 0;
    /*height: 50%;*/
    height: 40%;
    position: relative;
    z-index: 1;
}

.treeline {
    background-position: top left;
    background-repeat: repeat-x;
    width: 100%;
    position: fixed;
    height: 40%;
    z-index: -1;
    image-rendering:pixelated;
}

.treeline#wayback {
    background-image: url("/_assets/img/trees_4.png");
    background-image: url("/_assets/img/trees_4.png"), linear-gradient(to bottom, transparent 0, transparent 197px, #2c5b2b 197px);
    margin-top: 0px;
    @include breakpoint(large) {
        margin-top: 10px;
    }
}

.treeline#midback {
    background-image: url("/_assets/img/trees_3.png");
    background-image: url("/_assets/img/trees_3.png"), linear-gradient(to bottom, transparent 0, transparent 197px, #1b4136 197px);
    margin-top: -43px;
    @include breakpoint(large) {
        margin-top: 60px;
    }
}

.treeline#midfront {
    background-image: url("/_assets/img/trees_2.png");
    background-image: url("/_assets/img/trees_2.png"), linear-gradient(to bottom, transparent 0, transparent 132px, #15353c 132px);
    margin-top: 73px;
    @include breakpoint(large) {
        margin-top: 200px;
    }
}

.front-tree#upfront {
    width: 100%;
    height: 100%;
    padding-top: 100px;
    @include breakpoint(large) {
        padding-top: 0px;
    }
    @include breakpoint(xxlarge) {
        background: url('/_assets/img/trees_1.png') left bottom repeat-x;
    }
    position: relative;
}

///////
///
///
