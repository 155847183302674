.portfolio {
    background-color: white;
    section {
        margin-bottom: 2.5rem;
        @include breakpoint(medium) {
            margin-bottom: 5rem;
        }
    }
    footer {
        padding-top: 5rem;
        padding-bottom: 5rem;
        position: relative;
    }
    .fine-print {
        color: $brand-gray;
        a {
            color: $brand-gray;
        }
    }
    .work__image {
        @include breakpoint(small only){
            padding: 0 2rem;
        }
        img {
            max-width: 100%;
        }
    }

    header {
        @include breakpoint(small only) {
            padding-bottom: 3rem;
        }
    }
    .button-group__text {
        @include breakpoint(medium) {
            top: 0.4rem;
        }
    }
    .footer__logo {
        margin-top: 1.2rem;
        padding-top: 0;
        @include breakpoint(small only) {
            position: relative;
            top: 2px;
        }
        @include breakpoint(medium) {
            margin-top: 0;
            padding-top: .8rem;
        }
    }


}

.portfolio-padding {
    padding-top: 1rem;
    padding-bottom: 1rem;
    @include breakpoint(medium) {
        padding-top: 3rem;
        padding-bottom: 3rem;
    }
}

.portfolio-hero__wrapper {
    background-color: #0c182c;
    background-image: url(/_assets/img/wood_transparent.png);
    .u-wrap {
        transform: scale(1.1);
    }
}

.portfolio-header {
    position: relative;
    z-index: 5;
    height: 90px;
    @include breakpoint(medium) {
        height: 95px;
    }
    @include breakpoint(large) {
        height: 100px;
    }
    &--post {
        @include breakpoint(medium) {
            height: 63px;
        }
    }
}

.portfolio-header__bar {
    padding: 1rem;
    @include breakpoint(large) {
        padding: 1rem 3rem;
        padding-bottom: .5rem;
    }
    width: 100%;
    @include breakpoint(medium) {
        position: fixed;
        transform: translateY(-100%);
    }
    background-color: white;
    z-index: 5;
    transition: transform .5s ease-in-out;
}

.portfolio-header__bar--visible {
    transform: translateY(0%);
}

.portfolio-logo {
    width: 2rem;
    float: left;
    @include breakpoint(small only) {
        display: none;
    }
    path {
        fill: $brand-green;
    }
    display: inline-block;
}

.portfolio-header__info {
    float: left;
    display: inline-block;
    color: $brand-gray;
    font-size: .8rem;
    @include breakpoint(small only) {
        position: absolute;
    }
    @include breakpoint(medium) {
        padding-top: .3rem;
    }
    @include breakpoint(large) {
        padding-top: 0rem;
        font-size: 1rem;
    }
    div {
        display: block;
        @include breakpoint(medium) {
            display: inline-block;
            margin-left: 1rem;
        }
    }
    p {
        margin-bottom: 0;
        display: inline-block;
    }
    span {
        color: $brand-green;
        font-weight: 800;
    }
}

.portfolio-header__other-work {
    @include breakpoint(small only) {
        right: 0rem;
        display: block;
    }
    font-size: .8rem;
    margin-top: .4rem;
    @include breakpoint(large) {
        font-size: 1rem;
        margin-top: .2rem;
    }
    float: right;
    font-family: $header-font-family;
}

.portfolio-text-block {
    color: $brand-gray;
    @include grid-column(12);
    padding-left: 1rem;
    padding-right: 1rem;
    @include breakpoint(medium) {
        @include grid-column(9);
        @include grid-column-position(center);
    }
    &--split {
        @include breakpoint(medium) {
            @include grid-column(12);
            @include grid-column-position(center);
        }
    }
    &--split>div:first-of-type {
        @include breakpoint(medium) {
            border-right: 1px solid $brand-green;
        }
    }
    &--recognition {
        @include breakpoint(medium) {
            @include grid-column(9);
            @include grid-column-unposition;
            @include grid-column-position(3);
        }
        @include breakpoint(large) {
            @include grid-column-position(0);
        }
    }
}

.portfolio-text-block__copy {
    p {
        margin-bottom: 1rem;
    }
    @include breakpoint(large) {
        text-align: center;
    }
    &--header {
        text-align: left;
    }
    &--split {
        text-align: left;
        margin-bottom: 0;
        @include breakpoint(medium) {
            p {
                padding: 1rem 2rem;
                margin-bottom: 0rem;
            }
        }
    }
}

.portfolio-text-block__headline {
    color: #249377;
    font-size: 1.6rem;
    line-height: 1.8rem;
    &--split {
        position: relative;
        margin: 0;
        text-align: left;
        padding-bottom: 1rem;
        @include breakpoint(medium) {
            padding: 1rem 2rem;
            text-align: right;
            position: relative;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    &--recognition {
        text-align: left;
    }
    &--header {
        text-align: left;
    }
}

.portfolio__recognition-wrapper {
    @include grid-column(12);
    @include breakpoint(medium) {
        @include grid-column(3);
        @include grid-column-position(-9);
    }
    @include breakpoint(large) {
        @include grid-column-position(0);
    }
}

.portfolio__recognition {
    border-left: 1px solid $brand-gray;
    padding-left: .5rem;
    @include breakpoint(medium) {
        padding: 1rem;
        border-left: none;
        border-right: 1px solid $brand-gray
    }
    @include breakpoint(large) {
        border-right: none;
        border-left: 1px solid $brand-gray
    }
    color: $brand-gray;
    p {
        margin-bottom: 0rem;
        font-size: .8rem;
    }
}

.row.portfolio__image-block {
    @include breakpoint(small only) {
        .medium-4,.medium-6 {
            img {
                margin-bottom: 2rem;
            }
        }
    }
}


/*
//Joining multiple fullwidth /_assets/img into one
@include breakpoint(small only){
.row.expanded.portfolio__image-block img {
    margin-bottom: 0 !important;
}
}
*/