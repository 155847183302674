//Active nav item highlight
.panel--news.is-open ~ .panel--menu  {
    .nav__item3 {
        background-position: 100% 50%;
    }
}

.news-panel__story {
    @include grid-column(11);
    @include grid-column-position(center);
    margin-top: 4rem;
    text-align: center;
    @include breakpoint(large) {
        padding-left: 0 !important;
    }
}
.news-panel__story:not(:last-of-type) {
    border-bottom: 1px solid $brand-green;
}

.news-panel__story-image {
    overflow: hidden;
        border: 6px solid $brand-white;
    img {
        transition: transform .7s ease-in-out;
        &:hover {
            transform:scale(1.1);
        }
    }
    margin-bottom: 1.5rem;
}

.news-panel__story-title {
    font-size: 1.7rem;
    text-align: left;
    color:$brand-white;
    line-height: 1.9rem;
}

.news-panel__story-copy {
    line-height: 1.2rem;
    text-align: left;
}