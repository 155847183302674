.approach-graphic {
    overflow: visible !important;
    .logo {
        animation: 1s logo-in cubic-bezier(0.25, 0.46, 0.45, 0.94);
        animation-fill-mode: both;
        animation-delay: 1s;
    }

    .logo2 {
        animation: .7s logo2-in cubic-bezier(0.25, 0.46, 0.45, 0.94);
        animation-fill-mode: both;
        animation-delay: 1.6s;
        transform-origin: center;
    }
    .logo3 {
        animation: .7s logo3-in cubic-bezier(0.25, 0.46, 0.45, 0.94);
        animation-fill-mode: both;
        animation-delay: 1.8s;
        transform-origin: center;
    }
    .left__ {
        &word1,
        &line1 {
            animation: .7s left-word-in cubic-bezier(0.25, 0.46, 0.45, 0.94);
            animation-fill-mode: both;
            animation-delay: 2.4s;
            transform-origin: center;
        }
        &word2,
        &line2 {
            animation: .7s left-word-in cubic-bezier(0.25, 0.46, 0.45, 0.94);
            animation-fill-mode: both;
            animation-delay: 2.6s;
            transform-origin: center;
        }
        &word3,
        &line3 {
            animation: .7s left-word-in cubic-bezier(0.25, 0.46, 0.45, 0.94);
            animation-fill-mode: both;
            animation-delay: 2.8s;
            transform-origin: center;
        }
    }

    .right__ {
        &word1,
        &line1 {
            animation: .7s right-word-in cubic-bezier(0.25, 0.46, 0.45, 0.94);
            animation-fill-mode: both;
            animation-delay: 2.4s;
            transform-origin: center;
        }
        &word2,
        &line2 {
            animation: .7s right-word-in cubic-bezier(0.25, 0.46, 0.45, 0.94);
            animation-fill-mode: both;
            animation-delay: 2.6s;
            transform-origin: center;
        }
        &word3,
        &line3 {
            animation: .7s right-word-in cubic-bezier(0.25, 0.46, 0.45, 0.94);
            animation-fill-mode: both;
            animation-delay: 2.8s;
            transform-origin: center;
        }
    }
}

@keyframes logo-in {
    from {
        opacity: 0;
        transform: translateY(20%);
    }
    to {
        opacity: 1;
        transform: translateY(0%);
    }
}

@keyframes logo2-in {
    from {
        opacity: 0;
        transform: scale(.68);
    }
    to {
        opacity: .5;
        transform: scale(1);
    }
}

@keyframes logo3-in {
    from {
        opacity: 0;
        transform: scale(0.76);
    }
    to {
        opacity: .3;
        transform: scale(1);
    }
}


@keyframes left-word-in {
    from {
        opacity: 0;
        transform: translateX(-20%);
    }
    to {
        opacity: 1;
        transform: translateX(0%);
    }
}

@keyframes right-word-in {
    from {
        opacity: 0;
        transform: translateX(20%);
    }
    to {
        opacity: 1;
        transform: translateX(0%);
    }
}


